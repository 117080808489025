<template>
  <div class="login">
    <img src="@/assets/img/login.png" alt="">
    <!-- <p>{{query}}</p> -->
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data () {
    return {
      res :'',
      query:''
    }
  },
  mounted () {
    if ( window.location.href.split('?')[1]) {
      this.query = window.location.href.split('?')[1]
    }
    console.log(this.query);
    let query = this.query
    let vars = query.split("&");
    let obj = {}
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      obj[pair[0]] = pair[1]
    }
    console.log(ZWJSBridge);
    const sUserAgent = window.navigator.userAgent.toLowerCase()
    const bIsDtDreamApp = sUserAgent.indexOf('dtdreamweb') > -1 // 浙里办APP
    const bIsAlipayMini = sUserAgent.indexOf('miniprogram') > -1 && sUserAgent.indexOf('alipay') > -1
    if (!obj.ticket) {
      if (bIsAlipayMini) {
        console.log('------------miniapp----------------');
        ZWJSBridge.onReady(() => {
          ZWJSBridge.close({}).then(res => {
            console.log(res)
          }).catch(err => {
            console.log(err)
          })
        ZWJSBridge.openLink({
          "url": 'https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&servicecode=lbt'
        }).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
        })
      }
      if (bIsDtDreamApp) {
        console.log('------------app----------------');
        ZWJSBridge.onReady(() => {
          ZWJSBridge.close({}).then(res => {
            console.log(res)
          }).catch(err => {
            console.log(err)
          })
          ZWJSBridge.openLink({
          "url": 'https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=lbt'
        }).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
        })
      }
    }
    // 浙里办支付宝小程序
    if (bIsDtDreamApp || bIsAlipayMini) {
      setTimeout(() => {
        if (obj.ticket && obj.ticket.split('#')[0]) {
          obj.ticket = obj.ticket.split('#')[0]
        }
        this.$http({
          url: this.$http.adornUrl('/zlb/login/zlbapp'),
          method: 'post',
          params:this.$http.adornParams({
            st:obj.ticket
          },false)
          }).then(({data})=> {
            if (data.code == 0) {
              if (data.data.orgList) {
                Vue.prototype.$orgList = data.data.orgList.list
                Vue.prototype.$userId = data.data.orgList.userId
                this.$router.push('/community-change')
                return
              }
              this.$cookies.set('token', data.data.userForm.token)
              this.$cookies.set('orgId', data.data.userForm.orgId)
              console.log(Vue);
              Vue.prototype.$orgId = Number(data.data.userForm.orgId)
              Vue.prototype.$userId = data.data.userForm.userId
              Vue.prototype.$userName = data.data.userForm.userName
              Vue.prototype.$orgName = data.data.userForm.orgName
              this.$cookies.set('userId', data.data.userForm.userId)
              aplus_queue.push({action: 'aplus.setMetaInfo', arguments: ['_hold', 'BLOCK']});
              aplus_queue.push({action: "aplus.setMetaInfo", arguments: ["_user_nick", data.data.userForm.zlb_userName]});
              aplus_queue.push({action: "aplus.setMetaInfo", arguments: ["_user_id", data.data.userForm.zlb_userId]});
              aplus_queue.push({action: 'aplus.setMetaInfo', arguments: ['_hold', 'START']});
              this.$router.push('/home')
            } else {
              this.$router.push('/404')
            }
            },err=> {
              this.$router.push('/404')
            })
      }, 2000);

      console.log('-------------------zlb---------------------');
      ZWJSBridge.onReady(() => {
        let long = ''
        let lati = ''
        let  userType = ''
        ZWJSBridge.setTitle({
          "title": "居民果果"
        }).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
        ZWJSBridge.getUserType({}).then((result) => {
          userType = result.getUserType
          }).catch((error) => {
          console.log(error);
        });
        ZWJSBridge.getLocation({}).then(res => {
          long = res.longitude
          lati = res.latitude
        }).catch(err => {
          console.log(err)
        })
        aplus_queue.push({
        'action':'aplus.record',
        'arguments':['/yourTrackerEventCode', 'CLK', {
          gdLbs:'高德 GPS 定位',
          long:long,
          lati:lati,
          site_id:'站点 ID',
          userType:userType,
          zlb_serviceId:'lbt',
          pageId:'应用页面 ID',
          pageName:'应用页面名称',
          miniAppId:'2001810480' }]
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  overflow: hidden;
  height: 100vh;
  img {
    width: 100%;
    //margin-top: -100px;
  }
}
</style>
